import contact from './lib/contact'
import rebox from './lib/rebox'
import { toggleBelowFold, scrollSpy, smoothScroll } from './lib/scroll'

$(document).ready(function () {
  contact('#contact-form')
  rebox('.instanode-headstart-thumbimage')
  toggleBelowFold()
  // scrollSpy('.header-nav-sub:visible', {offset: 0})
  // smoothScroll('a[href^="#"]', {offset: 0})

  $('.header-nav li a').click(function () {
    $('.navbar-toggler ').addClass('collapsed')
    $('.header-nav-content.collapse').removeClass('show')
  })

  $(document).scroll(function () {
    var scroll = $(this).scrollTop()
    if (scroll > 0) {
      $('.header').addClass('fixed')
    } else {
      $('.header').removeClass('fixed')
    };
  })
})

$(document).ready(function () {
  const allContainer = document.querySelectorAll('.grid-content')

  window.onscroll = function () {
    allContainer.forEach(section => {
      if (section.getBoundingClientRect().top <= 800) {
        section.classList.add('active')
      }
    })
  }
})

if (window.innerWidth <= 991) {
  const contentCollection = document.querySelector('.services-collection--wrapper .neos-contentcollection')
  const scrollbarWrapper = document.querySelector('.scrollbar--wrapper')
  const scrollbar = document.createElement('div')
  scrollbar.id = 'custom-scrollbar'
  scrollbarWrapper.appendChild(scrollbar)

  const minScrollbarWidth = 20
  const maxScrollbarLeft = 90

  function updateScrollbar() {
    const scrollPercentage = (contentCollection.scrollLeft / (contentCollection.scrollWidth - contentCollection.clientWidth)) * 100;
    const containerWidth = contentCollection.clientWidth;
    let scrollbarWidth = (containerWidth / contentCollection.scrollWidth) * 100;

    // Stellen Sie sicher, dass die Scrollbar nicht weiter als 90% nach links geht und nicht kleiner als die Mindestbreite ist
    const maxScrollbarWidth = containerWidth - 25; // Abstand von rechts
    scrollbarWidth = Math.max(minScrollbarWidth, Math.min(scrollbarWidth, maxScrollbarWidth));
    const scrollbarLeft = Math.min(maxScrollbarLeft, scrollPercentage);

    scrollbar.style.left = scrollbarLeft + '%'
    scrollbar.style.width = scrollbarWidth + '%'
  }

  updateScrollbar()

  contentCollection.addEventListener('scroll', updateScrollbar)

  scrollbar.addEventListener('mousedown', (e) => {
    const initialX = e.clientX
    const initialScrollLeft = contentCollection.scrollLeft

    function moveScroll (e) {
      const deltaX = e.clientX - initialX
      contentCollection.scrollLeft = initialScrollLeft + (deltaX / scrollbar.clientWidth) * (contentCollection.scrollWidth - contentCollection.clientWidth - 25)
    }

    function stopScroll() {
      document.removeEventListener('mousemove', moveScroll)
      document.removeEventListener('mouseup', stopScroll)
    }

    document.addEventListener('mousemove', moveScroll)
    document.addEventListener('mouseup', stopScroll);
  })

  if (contentCollection.scrollWidth > contentCollection.clientWidth) {
    scrollbar.style.display = 'block'
  } else {
    // Wenn der Inhalt nicht mehr gescrollt werden kann, behalten Sie die Mindestbreite und maximale Position von links bei
    scrollbar.style.display = 'block'
    scrollbar.style.width = minScrollbarWidth + 'px'
    scrollbar.style.left = maxScrollbarLeft + '%'
  }
}

$('#go-back').on('click', function () {
  history.back()
})